<template>
  <div class="comProbCss">
    <Navbar :pageName="pageName" />
    <div class="contentMsgCss">
      <div class="leftContentCss">
        <div class="leftTitleCss">提交您的问题</div>
        <div class="borderLineCss" />
        <div class="titleAndInputCss" v-for="(item, index) in inputList" :key="index">
          <div class="titleCss">{{item.title}}</div>
          <div class="inputContentCss">
            <div class="iconCss"><svg-icon :icon-class="item.iconClass" width="1.5rem" height="1.5rem" color="#666666" /></div>
            <input v-if="item.inputType === 'text'" type="text" v-model="item.val" />
            <textarea v-if="item.inputType === 'textarea'" v-model="item.val" />
          </div>
        </div>
        <button class="detailButCss">提交</button>
      </div>
      <div class="rightContentCss">
        <div class="rightTitleCss">{{pageName}}</div>
        <Tab
          :allCur="8"
          :cur="programmeMsgType"
          :tabTitleArr="tabTitleArr"
          isSelectBackColor="#e84c3d"
          @change="tabChange"
        />
        <div class="progItemAllCss" v-for="(item, index) in progList" :key="index" @click="clickProgItem(item)">
          <div class="progItemTitleCss">
            <div class="iconContentCss">
              <svg-icon :icon-class="item.iconClass" width="1.5rem" height="1.5rem" color="white"  />
            </div>
            <div class="progTitleCss">{{item.title}}</div>
            <div class="openOrCloseCss">
              <svg-icon :icon-class="item.isOpen ? 'close' : 'open'" width="1.5rem" height="1.5rem" color="white"  />
            </div>
          </div>
          <div class="detailsAllMsgCss" :style="{padding: item.isOpen && item.detailList ? '1rem' : 0,height: item.isOpen && item.detailList ? item.detailList.length*1.4+'rem'  : 0}">
            <div
              style="height:1.4rem;line-height:1.4rem"
              v-for="(item, index) in item.detailList"
              :key="index"
            >{{item.msg}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/views/home/components/Navbar.vue'
import Tab from '@/components/myComp/tab/Tab.vue'

const steam = [ // 硬件
  {
    iconClass: 'quesMark',
    title: '智能终端为什么不上线？',
    isOpen: false,
    detailList: [
      { msg: '1、信号问题'},
      { msg: '首先检查终端所在地区运营商信号是否覆盖。'},
      { msg: '2、物联卡的问题'},
      { msg: '检查物联卡是否欠费或损坏，可选择换张物联卡尝试连接。'},
      { msg: '3、Modem device选项设置：'},
      { msg: '4G设置应为:TTY/USB3'},
      { msg: '2G设置应为:TTYS5'},
      { msg: '4、物联卡APN设置：'},
      { msg: '移动物联卡APN：CMMTM CMIOT'},
      { msg: '移动普通卡APN：CMNET'},
      { msg: '电信普通卡APN：CTNET'},
      { msg: '5、天线'},
      { msg: '检查2G、4G的天线是否分别配对正确'},
      { msg: '6、卡没插对或没插稳'},
      { msg: '7、软件版本问题，拨号进程没有启动，复位或重启后正常。'}
    ]
  },
  {
    iconClass: 'quesMark',
    title: '智能终端上电后指示灯不亮？',
    isOpen: false,
    detailList: [
      { msg: '检查电源适配器是否正常，可更换适配器尝试重新开机。'}
    ]
  }
]
const cloud = [ // 软件
  {
    iconClass: 'quesMark',
    title: '监控平台可以做到账号分级管理吗，即不同的账号管理不同的设备？',
    isOpen: false,
    detailList: [
      { msg: '完全可以'}
    ]
  }
]

export default {
  name: 'comProb',
  components: {
    Navbar,
    Tab
  },
  data() {
    return {
      pageName: '',
      inputList: [
        { title: '姓名', val: '', iconClass: 'user', inputType: 'text' },
        { title: '邮箱', val: '', iconClass: 'mail', inputType: 'text' },
        { title: '电话', val: '', iconClass: 'phone', inputType: 'text' },
        { title: '问题', val: '', iconClass: 'edit', inputType: 'textarea' }
      ],
      tabTitleArr: [
        { title: '硬件', iconName: 'steam' },
        { title: '软件', iconName: 'cloud' }
      ],
      programmeMsgType: 0, // 方案组成类型
      progList: steam
    }
  },
  mounted() {
    this.pageName = this.$route.query.pageName
  },
  methods: {
    tabChange(e) {
      this.programmeMsgType = e
      switch(this.programmeMsgType) {
        case 0: this.progList = steam; break
        case 1: this.progList = cloud; break
      }
    },
    clickProgItem(row) {
      this.progList.forEach(item => {
        if (row.title === item.title) {
          item.isOpen = !item.isOpen
        } else {
          item.isOpen = false
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
leftWidth = 17rem

.comProbCss {
  width 99.8rem
  background-color #333439
  font-size 0.8rem
  .contentMsgCss {
    padding 2rem 18rem 4rem 18rem
    .leftContentCss {
      float left
      width leftWidth
      padding-right 2.8rem
      .leftTitleCss {
        font-size 1.2rem
        color white
        height 3rem
        line-height 3rem
      }
      .borderLineCss {
        width 1.5rem
        border 2px solid white
        margin-bottom 0.5rem
      }
      .titleAndInputCss {
        margin-top 0.5rem
        width leftWidth
        .titleCss {
          color #666666
          font-weight bold
          height 1.6rem
          line-height 1.6rem
        }
        .inputContentCss {
          width leftWidth
          position relative
          .iconCss {
            position absolute
            top 0.2rem
            right 0.2rem
            height 100%
          }
          input {
            padding 0 2rem 0 0.5rem
            width 14.5rem
            height 2rem
            color #666666
            border none
            outline none // 焦点置于input框内时不显示边框
          }
          textarea {
            padding 0.3rem 2rem 0.3rem 0.5rem
            width 14.5rem
            height 6rem
            color #666666
            border none
            outline none // 焦点置于input框内时不显示边框
          }
        }
      }
      button {
        margin-top 1rem
        width 8rem
        height 2rem
      }
    }
    .rightContentCss {
      float left
      width 44rem
      .rightTitleCss {
        font-size 1.2rem
        color white
        height 3rem
        line-height 3rem
        font-weight bolder
      }
      .progItemAllCss {
        overflow hidden
        .progItemTitleCss {
          margin-top 0.3rem
          width 43rem
          padding 0.5rem
          background-color #999999
          transition background-color .5s
          cursor pointer // 鼠标光标变为手的形状
          .iconContentCss {
            float left
            padding 0.15rem 0.5rem 0 0
          }
          .progTitleCss {
            float left
            font-size 1.2rem
            color white
          }
          .openOrCloseCss {
            float right
            padding 0.15rem 0 0 0
          }
        }
        .detailsAllMsgCss {
          background-color white
          transition-property padding, height
          transition-duration 0.5s, 0.5s
        }
        .progItemTitleCss:after { // 使 contentMsgCss 适应内部高度
          clear both
          content ''
          width 0
          height 0
          display block
          visibility hidden
        }
        .progItemTitleCss:hover {
          background-color #e84c3d
        }
      }
    }
  }
  .contentMsgCss:after { // 使 contentMsgCss 适应内部高度
    clear both
    content ''
    width 0
    height 0
    display block
    visibility hidden
  }
}
</style>
